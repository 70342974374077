<template>
    <v-dialog persistent :value="isFormActive" max-width="850">
        <ValidationObserver ref="observerFormRole" v-slot="{ handleSubmit, reset }">
            <v-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                <v-card :loading="isLoading" :disabled="isLoading">
                    <v-card-title>
                        <span class="text-h5">{{ getTrad(`Roles.Form.${action}.title`) }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <p class="text-subtitle-1 font-weight-medium" v-text="getTrad('Roles.Form.details')"></p>
                            <ValidationProvider v-show="false" vid="roleId">
                                <v-text-field v-model="form.id"></v-text-field>
                            </ValidationProvider>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider :name="getTrad('role.name')" mode="lazy" rules="required|uniqueRoleType:@roleId" v-slot="{ errors }">
                                        <v-text-field :error-messages="errors" hide-details="auto" outlined dense v-model.trim="form.name" :label="getTrad('role.name')"></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider :name="getTrad('role.description')" v-slot="{ errors }">
                                        <v-textarea hide-details="auto" outlined dense v-model.trim="form.description" :label="getTrad('role.description')"></v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <p class="text-subtitle-1 font-weight-medium" v-text="getTrad('Roles.Form.permissions')"></p>
                            <v-row>
                                <v-col cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel v-for="(plugin, keyPlugin) in groupedByPlugin" :key="keyPlugin">
                                            <v-expansion-panel-header>
                                                {{ keyPlugin }}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div v-for="(subject, keySubject) in plugin" :key="keySubject">
                                                    <div class="d-flex flex-row justify-center align-center">
                                                        <span>{{ keySubject }}</span>
                                                        <v-divider class="mx-4"></v-divider>
                                                        <v-checkbox
                                                            :label="getTrad('Roles.Form.selectAll')"
                                                            v-bind="selection(keyPlugin, keySubject)"
                                                            @change="onSelectAll($event, keyPlugin, keySubject)"></v-checkbox>
                                                    </div>
                                                    <v-row>
                                                        <v-col v-for="(permission, keyPermission) in subject" :key="keyPermission">
                                                            <v-checkbox
                                                                hide-details
                                                                @change="onSelectOne($event, keyPlugin, keySubject, permission.action)"
                                                                :value="isSelected(permission.id)"
                                                                :label="permission.action"></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text type="reset" @click="onCancel">
                            {{ getTrad('Form.cancel') }}
                        </v-btn>
                        <v-btn color="primary" type="submit">
                            {{ getTrad('Form.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
    import getTrad from '../../i18n/getTrad';
    import { merge, assign } from 'lodash';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { addRole } from '../../api/roles';
    import slugify from 'slugify';

    const initialState = () => {
        return {
            isDisabled: false,
            isLoading: false,
            form: {
                name: '',
                type: '',
                description: '',
                pluginPermissions: [],
            },
            pluginPermissions: [],
        };
    };
    export default {
        name: 'FormRoles',
        mixins: [getTrad],
        model: {
            prop: 'isFormActive',
            event: 'update:isFormActive',
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            isFormActive: {
                type: Boolean,
                default: false,
            },
            action: {
                type: String,
                default: 'add',
            },
            _pluginPermissions: {
                type: Array,
                default: () => [],
            },
            role: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                ...initialState(),
            };
        },
        watch: {
            isFormActive(val) {
                if (val) {
                    this.pluginPermissions = merge([], this._pluginPermissions);
                    if (this.isAdding) {
                        this.form = merge({}, initialState().form);
                    } else {
                        this.form = merge({}, this.role);
                        for (let permission of this.role.pluginPermissions) {
                            let p = this.pluginPermissions.find((perm) => perm.id == permission.id);
                            if (p) p.checked = true;
                        }
                    }
                }
            },
        },
        computed: {
            isAdding() {
                return this.action === 'add';
            },
            groupedByPlugin() {
                // group pluginPermissions by plugin and subject
                const grouped = {};
                this.pluginPermissions.forEach((permission) => {
                    const plugin = permission.plugin;
                    if (!grouped[plugin]) {
                        grouped[plugin] = {};
                    }
                    const subject = permission.subject;
                    if (!grouped[plugin][subject]) {
                        grouped[plugin][subject] = [];
                    }
                    grouped[plugin][subject].push(permission);
                });
                return grouped;
            },
            isSelected() {
                return (id) => {
                    return this.form.pluginPermissions.findIndex((item) => item.id == id) > -1;
                };
            },
            selection() {
                return (plugin, subject) => {
                    let some = this.groupedByPlugin[plugin][subject].some((item) => {
                        return item.checked;
                    });
                    let every = this.groupedByPlugin[plugin][subject].every((item) => {
                        return item.checked;
                    });
                    return {
                        indeterminate: some && !every,
                        value: every,
                    };
                };
            },
        },
        methods: {
            onCancel() {
                this.$refs.observerFormRole.reset();
                merge(this.$data, initialState());
                this.$emit('update:isFormActive', false);
            },
            onSelectAll(value, plugin, subject) {
                let found = this.pluginPermissions.filter((item) => {
                    return item.plugin == plugin && item.subject == subject;
                });
                for (let i = 0; i < found.length; i++) {
                    found[i].checked = value;
                    if (value) this.form.pluginPermissions.push(found[i].id);
                    else this.form.pluginPermissions.splice(this.form.pluginPermissions.indexOf(found[i].id), 1);
                }
            },
            onSelectOne(value, id) {
                let found = this.pluginPermissions.find((item) => item.id == id);
                if (found) {
                    found.checked = value;
                    if (value) this.form.pluginPermissions.push(id);
                    else this.form.pluginPermissions.splice(this.form.pluginPermissions.indexOf(id), 1);
                }
            },
            onSubmit() {
                if (this.isAdding) this.onAdd();
                else this.onUpdate();
            },
            async onAdd() {
                this.isLoading = true;
                let form = assign({}, this.form);
                form.pluginPermissions = this.pluginPermissions
                    .filter((item) => {
                        return item.checked;
                    })
                    .map((item) => item.id);

                form.type = slugify(form.name, { lower: true });

                await addRole(form)
                    .then(() => {
                        this.$snackbar({
                            text: this.getTrad(`Form.${this.action}.user`),
                            color: 'success',
                            timeout: 2000,
                        });
                        this.$emit('action');
                        this.onCancel();
                    })
                    .catch((error) => {
                        this.$snackbar({
                            text: this.$t('shared.commonError'),
                            color: 'error',
                        });
                        console.log(error);
                    });
                this.isLoading = false;
            },
            onUpdate() {},
        },
    };
</script>

<style lang="scss" scoped></style>
