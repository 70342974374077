var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.isFormActive,"max-width":"850"}},[_c('ValidationObserver',{ref:"observerFormRole",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getTrad(("Roles.Form." + _vm.action + ".title"))))])]),_c('v-card-text',[_c('v-container',[_c('p',{staticClass:"text-subtitle-1 font-weight-medium",domProps:{"textContent":_vm._s(_vm.getTrad('Roles.Form.details'))}}),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"vid":"roleId"}},[_c('v-text-field',{model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.getTrad('role.name'),"mode":"lazy","rules":"required|uniqueRoleType:@roleId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":"auto","outlined":"","dense":"","label":_vm.getTrad('role.name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.getTrad('role.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","outlined":"","dense":"","label":_vm.getTrad('role.description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.description"}})]}}],null,true)})],1)],1),_c('p',{staticClass:"text-subtitle-1 font-weight-medium",domProps:{"textContent":_vm._s(_vm.getTrad('Roles.Form.permissions'))}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',_vm._l((_vm.groupedByPlugin),function(plugin,keyPlugin){return _c('v-expansion-panel',{key:keyPlugin},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(keyPlugin)+" ")]),_c('v-expansion-panel-content',_vm._l((plugin),function(subject,keySubject){return _c('div',{key:keySubject},[_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('span',[_vm._v(_vm._s(keySubject))]),_c('v-divider',{staticClass:"mx-4"}),_c('v-checkbox',_vm._b({attrs:{"label":_vm.getTrad('Roles.Form.selectAll')},on:{"change":function($event){return _vm.onSelectAll($event, keyPlugin, keySubject)}}},'v-checkbox',_vm.selection(keyPlugin, keySubject),false))],1),_c('v-row',_vm._l((subject),function(permission,keyPermission){return _c('v-col',{key:keyPermission},[_c('v-checkbox',{attrs:{"hide-details":"","value":_vm.isSelected(permission.id),"label":permission.action},on:{"change":function($event){return _vm.onSelectOne($event, keyPlugin, keySubject, permission.action)}}})],1)}),1)],1)}),0)],1)}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","type":"reset"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.getTrad('Form.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.getTrad('Form.save'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }